import { asapScheduler, from, Observable, Subscription } from "rxjs"
import { observeOn, reduce } from "rxjs/operators"

export function numberFormat(num: any, maximumFractionDigits: number = 0): string {
  return new Intl.NumberFormat("ru-RU", { maximumFractionDigits }).format(parseFloat(num))
}

export function isFunction(value: any): boolean {
  return typeof value === "function"
}

export function isObject(obj: any): boolean {
  return typeof obj === "object"
}

export function isArray(arr: any): boolean {
  return Array.isArray(arr)
}

export function isEmptyArray(arr: any): boolean {
  return isArray(arr) && arr.length === 0
}

export function isArrayBuffer(data: any): boolean {
  return data instanceof ArrayBuffer
}

export function isString(str: any): boolean {
  return typeof str === "string"
}

export function isEmptyString(str: any): boolean {
  return isString(str) && str.length === 0
}

export function isEmptyAll(obj: any): boolean {
  return isEmpty(obj) || isEmptyString(obj) || isEmptyArray(obj)
}

export function isNull(obj: any): boolean {
  return obj === null
}

export function isUndefined(obj: any): boolean {
  return typeof obj === "undefined"
}

export function isEmpty(obj: any): boolean {
  return isNull(obj) || isUndefined(obj)
}

export function isNotEmpty(obj: any): boolean {
  return !isEmpty(obj)
}

export function toBoolean(value: any): boolean {
  if (isString(value)) {
    return value
  }

  return isEmpty(value)
}

export function isLiveSubscription(subscription: Subscription): boolean {
  return !isEmpty(subscription) && !subscription.closed
}

export function unsubscribe(subscription: Subscription): void {
  if (isLiveSubscription(subscription)) {
    subscription.unsubscribe()
  }
}

/**
 * @fixme: Нет проверки на тип, если прислать сюда число, то функция вернет true, а должна вообще ошибку кидать
 * @param obj
 */
export function isEmptyObject(obj: any): boolean {
  return !(obj && Object.keys(obj).length > 0)
}

export function isPositivelyValue(value: any): any {
  return isString(value) || value
}

export function isSameObject<T>(obj1: T, obj2: T): boolean {
  if (isObject(obj1) && isObject(obj2)) {
    if (Object.is(Object.keys(obj1).length, Object.keys(obj2).length)) {
      const keys1: string[] = Object.keys(obj1)
      const keys2: string[] = Object.keys(obj2)
      if (keys1.every(((value) => keys2.indexOf(value) > -1))) {
        for (const key of keys1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!Object.is(obj1[ key ], obj2[ key ])) {
            return false
          }
        }
        return true
      }
      return false
    }
    return false
  }
  return false
}

export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  return arr.reduce((prevVal: any, currVal: any, currIndex: number, array: T[]) =>
    !(currIndex % chunkSize) ?
      prevVal.concat([ array.slice(currIndex, currIndex + chunkSize) ]) :
      prevVal, [])
}

export function chunkAsync<T>(arr: T[], chunkSize: number): Observable<T[][]> {
  return from(arr).pipe(
    observeOn(asapScheduler),
    reduce((prevVal: any, currVal: any, currIndex: number) =>
      !(currIndex % chunkSize) ? prevVal.concat([ arr.slice(currIndex, currIndex + chunkSize) ]) : prevVal, [])
  )
}

export function assignData(data: any): any {
  return JSON.parse(JSON.stringify(data))
}

export function genRandomString(minPassLength: number): string {
  return Math.random().toString(36).slice(-minPassLength)
}

export function toArray<T>(to: Map<any, T> | T[] | Iterable<T> | ArrayLike<T>): T[] {
  if (to instanceof Map) {
    return Array.from(to.values())
  }

  return Array.from(to)
}

export function arrayToMap<T, K = T[ keyof T ]>(array: T[], getKeyValue: (item: T) => K): Map<K, T> {
  return array.reduce((acc, curr) => {
    acc.set(getKeyValue(curr), curr)
    return acc
  }, new Map<K, T>())
}

export function flatArray<T>(array: (T | (T | T[])[])[]): T[] {
  const flatted: T[] = []

  const flat: any = (arr: (T | (T | T[])[])[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    arr.forEach((item: T) => {
      if (Array.isArray(item)) {
        flat(item)
      } else {
        flatted.push(item)
      }
    })
  }

  flat(array)

  return flatted
}

export function coerceBooleanProperty(value: any): boolean {
  return (value !== null && typeof value !== "undefined") && `${ value }` !== "false"
}
