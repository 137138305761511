import { Action, ActionReducer, createReducer, on } from "@ngrx/store"
import { writeOrgData } from "../actions"
import { Org } from "../../tree/models"

const reducer: ActionReducer<any> = createReducer(
  {},
  on(writeOrgData, (state, { payload }) => ({ ...state, ...payload }))
)

export function orgReducer(state: Org, action: Action): any {
  return reducer(state, action)
}
