import { UserScope } from "@waliot/web-sdk"
import { LoadingState } from "../../../../global/shared/model"

/**
 * @deprecated use {@link UserAtomService}
 */
export interface UserState {
  name?: string
  orgId?: number
  uid?: number
  comment?: string
  email?: string
  jobPosition?: string
  login?: string
  phone?: string
  role?: string
  scopes?: UserScope[]
  loading?: LoadingState
  integrations?: Integration[]
}

export interface Integration {
  orgId: number
  integrationType: IntegrationType
  token: string
}

export enum IntegrationType {
  fuelCardPetrolPlus = "FUEL_CARD_PETROL_PLUS",
  fuelCardE100 = "FUEL_CARD_E_100",
  fuelCardRosneft = "FUEL_CARD_ROSNEFT",
  fuelCardOnlineOil = "FUEL_CARD_ONLINE_OIL"
}

export const initialUserState: UserState = {
  loading: LoadingState.quiet
}

export enum UserRole {
  root = "ROOT",
  user = "USER",
  admin = "ADMIN",
  superUser = "SUPER_USER",
  superAdmin = "SUPER_ADMIN"
}
