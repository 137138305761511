export enum WindowMode {
  report = "REPORT",
  maintenance = "MAINTENANCE",
  maintenanceReforge = "MAINTENANCE_REFORGE",
  map = "MAP",
  petrolPlus = "PETROL_PLUS",
  customTags = "CUSTOM_TAGS",
  share = "SHARE",
  notification = "NOTIFICATION",
  enterpriseResources = "ENTERPRISE_RESOURCES",
  news = "NEWS",
  bi = "BI"
}
