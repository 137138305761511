import { isEmptyAll } from "pmk/utils"

const _AUTH_PATH: string = "/uaa/oauth/token"

/**
 * @deprecated use {@link HIGHLIGHT_MAP_ZOOM}
 */
export const selectZoom: number = 14

const _COLORS: Readonly<Record<string, string>> = {
  yellow: "#ffc107",
  boyblue: "#7898dd",
  chilioil: "#882122",
  bluesky: "#0057a6",
  lightgreen: "#4ad6ad",
  emperador: "#612f22",
  limepunch: "#d7ef18",
  fiolet: "#673ab7",
  orange: "#f57c00",
  ultraviolet: "#553a63",
  pink: "#e91e63",
  sailorblue: "#032447",
  fuxy: "#a395da",
  arcadia: "#199989",
  coral: "#ff6f61"
}

const _COLOR_OBJECTS: Readonly<Record<string, string>> = {
  arcadia: "#199989",
  blue: "#2196f3",
  darkblue: "#3f51b5",
  offline: "#7E7E7E",
  orange: "#f57c00",
  parking: "#1565C0",
  pink: "#e91e63",
  purple: "#673ab7",
  red: "#f44336",
  trueRed: "#ff0000",

  emperador: "#612f22",
  bluesky: "#0057a6",
  green: "#2ecc71",
  chilioil: "#882122",
  ultraviolet: "#553a63",
  fiolet: "#673ab7",
  fuxy: "#a395da",
  boyblue: "#7898dd",
  online: "#2ECC71",
  sailorblue: "#032447",
  yellow: "#ffc107",
  lightgreen: "#4ad6ad",
  limepunch: "#d7ef18",
  persian_green: "#00A693"
}

const _EXTRA_IGNITION_COLORS: Readonly<Record<string, string>> = {
  contrastpurple: "#5A45F3",
  coral: "#ff6f61",
  cyan: "#00ffff",
  magenta: "#FF00FF",
  chartreuse: "#7FFF00",
  lightsalmon: "#FFA07A",
  rebeccapurple: "#663399",
  peru: "#CD853F"
}

const _COLORS_ARRAY: readonly string[] = Object.keys(_COLORS)
const _EXTRA_IGNITION_COLOR_NAMES: readonly string[] = Object.keys(_EXTRA_IGNITION_COLORS)

const apiEndpoint: any = Reflect.get(window, "API_ENDPOINT")
const authEndpoint: any = Reflect.get(window, "AUTH_ENDPOINT")
const shareEndpoint: any = Reflect.get(window, "SHARE_ENDPOINT")

export const baseApiUrl: string = isEmptyAll(apiEndpoint) ? "https://api.waliot.com/api" : apiEndpoint
export const authUrl: string = isEmptyAll(authEndpoint) ? "https://auth.waliot.com/uaa/oauth" : authEndpoint
export const sharePath: string = isEmptyAll(shareEndpoint) ? "https://share.waliot.com" : shareEndpoint

export const authPath: string = _AUTH_PATH
export const colorsMap: Readonly<Record<string, string>> = _COLORS
export const colorsObjects: Readonly<Record<string, string>> = _COLOR_OBJECTS
export const colorsArray: readonly string[] = _COLORS_ARRAY

export const extraIgnColorMap: Readonly<Record<string, string>> = _EXTRA_IGNITION_COLORS
export const extraIgnColorNames: readonly string[] = _EXTRA_IGNITION_COLOR_NAMES

/**
 * Буферное время которое отнимается и прибавляется
 * к времени трека каждой строки в отчетах внутри
 * которых есть треки
 */
export const BUFFER_MINUTES_FOR_TRACK_ADD: number = 5

/**
 * Время отображения маркера текущего местоположения
 * пользователя на карте в миллисекундах
 */
export const GEOLOCATION_MARKER_LIFE_TIME: number = 60_000

/**
 * Формат отображения даты
 * Часы:минуты:секунды, день.месяц.год
 * Все с ведущими нулями. Год полный.
 *
 * @example '16:39:47, 25.08.2020'
 *
 * @link https://date-fns.org/v2.15.0/docs/format
 */
export const DATE_FORMAT: string = "HH:mm:ss, dd.MM.yyyy"

/**
 * Интервал обновления иерархии
 * (в миллисекундах)
 */
export const HIERARCHY_UPDATE_INTERVAL: number = 60_000

/**
 * Интервал обновления состояний
 * (в миллисекундах)
 */
export const STATES_UPDATE_INTERVAL: number = 15_000

/**
 * Интервал обновления уведомлений
 * (в миллисекундах)
 */
export const NOTIFICATION_UPDATE_INTERVAL: number = 20_000

/**
 * Устанавливаемый зум карты, при выборе
 * объекта или гезоны в дереве
 */
export const HIGHLIGHT_MAP_ZOOM: number = 14

/**
 * Список возможных значений времени жизни **в часах** для токена шеринга
 * */
export const SHARE_TOKEN_LIFETIME_DURATIONS: number[] = [ 1, 6, 12, 24 ]

/**
 * Задержка для двойного клика в **милисекундах**
 */
export const DOUBLE_CLICK_DELAY: number = 180

/**
 * Максимальная длина интервала для отчетов
 */
export const MAX_ALLOWED_INTERVAL_IN_DAYS: number = 93
