import { DOCUMENT } from "@angular/common"
import { Component, HostBinding, Inject } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { BrowserStorageVariables } from "pmk/shared"
import { BrowserStorageService } from "pmk/shared/shared-services"
import { NAVIGATOR, WINDOW } from "pmk/shared/shared-services/tokens"
import { isSafariIOS, isTouchScreen } from "pmk/utils"
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from "rxjs"
import { distinctUntilChanged, takeUntil, tap } from "rxjs/operators"
import { RootState } from "./core/model"
import { takeSettingSelector } from "./core/selectors"

@Component({
  selector: "pmk-app",
  templateUrl: "./app.component.html",
  styleUrls: [ "./app.component.scss" ]
  // changeDetection: ChangeDetectionStrategy.OnPush TODO Вызывает проблемы с интерактивностью авторизации
})
export class AppComponent {
  @HostBinding("class.is-standalone")
  private isStandalone: boolean = this.checkStandalone()

  private destroy$: Subject<void> = new Subject()

  public showGarland$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  public lastTouchEnd: any = (new Date()).getTime()

  private safariIOSHack$: Observable<Event> = fromEvent(this.window, "mousemove").pipe(
    tap((event) => this.safariIOSHack(event))
  )

  private documentTouchmove$: Observable<unknown> = fromEvent(this.document, "touchmove").pipe(
    tap((e) => this.onTouchmove(e))
  )

  private documentTouchend$: Observable<unknown> = fromEvent(this.document, "touchend").pipe(
    tap((e) => this.onTouchend(e))
  )

  private autoScrollOnOrientationChange$: Observable<Event> = fromEvent(this.window, "orientationchange").pipe(
    tap(() => {
      setTimeout(() => {
        const bodyScrollHeight: number = this.document.body.scrollHeight
        this.window.scrollTo(0, bodyScrollHeight)
      }, 500)
    })
  )

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(WINDOW) private window: Window,
              @Inject(NAVIGATOR) private navigator: Navigator,
              private browserStorage: BrowserStorageService,
              private store$: Store<RootState>) {
    this.initHacks()
  }

  public ngOnInit(): void {
    this.showGarland$.next(this.browserStorage.onGet(BrowserStorageVariables.showGarland))
    this.changeShowGarland$.subscribe()
  }

  private changeShowGarland$: Observable<boolean> = this.store$.pipe(
    select(takeSettingSelector("showGarland")),
    takeUntil(this.destroy$),
    distinctUntilChanged(),
    tap((state: boolean) => this.showGarland$.next(state))
  )

  private onTouchmove(event: any): void {
    if (event.scale !== 1) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  private safariIOSHack(event: any): void {
    if (event.layerX < 0) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
    }
  }

  private onTouchend(event: any): boolean {
    const now: number = (new Date()).getTime()

    if (now - this.lastTouchEnd <= 300) {
      event.preventDefault()
    }

    this.lastTouchEnd = now
    return true
  }

  private initHacks(): void {
    const hacks: any[] = []
    if (isSafariIOS()) {
      hacks.push(this.safariIOSHack$)
      hacks.push(this.documentTouchend$)
      hacks.push(this.documentTouchmove$)
      hacks.push(this.autoScrollOnOrientationChange$)
    }

    if (isTouchScreen()) {
      hacks.push()
    }

    combineLatest(hacks).subscribe()
  }

  private checkStandalone(): boolean {
    if (("standalone" in this.navigator) && Reflect.get(this.navigator, "standalone")) {
      return true
    }

    return [ "fullscreen", "standalone", "minimal-ui" ].some(
      (displayMode) => this.window.matchMedia("(display-mode: " + displayMode + ")").matches
    )
  }
}
