/**
 * Сортировка массива по алфавиту в порядке убывания
 *
 * @example
 * ```typescript
 * const array = [
 *   { name: 'b' },
 *   { name: 'c' },
 *   { name: 'a' }
 * ]
 *
 * const sortedArray = sortAlphabeticASC(array, i => i.name)
 * console.log(sortedArray) // [
 *   { name: 'a' },
 *   { name: 'b' },
 *   { name: 'c' }
 * ]
 * ```
 *
 * @param toSort массив который нужно отсортировать
 * @param bySort функция которая возвращает поле по которому будет идти сортировка
 */
export function sortAlphabeticASC<T>(toSort: T[]|readonly T[], bySort: (item: T) => string | number): T[] {
  return toSort.slice().sort((a, b) => {
    const aField: string | number = bySort(a)
    const bField: string | number = bySort(b)

    if (aField < bField) {
      return -1
    }

    if (aField > bField) {
      return 1
    }
    return 0
  })
}
