import { CommonModule } from "@angular/common"
import { HttpClientModule } from "@angular/common/http"
import { Injectable, NgModule } from "@angular/core"
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { ServiceWorkerModule } from "@angular/service-worker"
import { EffectsModule } from "@ngrx/effects"
import { StoreModule } from "@ngrx/store"
import { StoreDevtoolsModule } from "@ngrx/store-devtools"
import { TranslateModule, TranslateService } from "@ngx-translate/core"

import { ToastrModule } from "ngx-toastr"
import { LocalStorageService, NgxWebstorageModule, SessionStorageService } from "ngx-webstorage"
import { BrowserStorageVariables } from "pmk/shared"
import { isNull } from "pmk/utils"

import enTranslations from "../assets/i18n/en.json"
import ruTranslations from "../assets/i18n/ru.json"

import { environment } from "../environments/environment"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { Language } from "./core/model"
import { reducers } from "./core/reducers"
import { GarlandComponent } from "./garland/garland.component"

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  public buildHammer(element: HTMLElement): HammerManager {
    return new Hammer(element, {
      touchAction: "auto"
    })
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production
    }),
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      preventDuplicates: true
    }),
    NgxWebstorageModule.forRoot({
      prefix: environment.production ? "navigator" : "navigator-dev"
    }),
    HammerModule,
    StoreModule.forRoot(<any>reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      name: environment.appName
    })
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    }
  ],
  bootstrap: [ AppComponent ],
  declarations: [ AppComponent, GarlandComponent ]
})
export class AppModule {
  constructor(private translate: TranslateService,
              private browserStore: LocalStorageService,
              private sessionStorageService: SessionStorageService) {
    this.translate.addLangs(Object.values(Language))

    this.translate.setTranslation(Language.ru, ruTranslations)
    this.translate.setTranslation(Language.en, enTranslations)

    const browserLanguage: any = this.browserStore.retrieve(BrowserStorageVariables.language)
    const langValue: any = isNull(browserLanguage) ? Language.ru : browserLanguage
    this.translate.use(langValue)

    this.sessionStorageService.store(BrowserStorageVariables.language, langValue)
    this.browserStore.store(BrowserStorageVariables.language, langValue)
  }
}
