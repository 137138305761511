import { StateType } from "@waliot/web-sdk"

export type CommonStateTypes = "OFFLINE" | "ONLINE" | "PARKING"

export const COMMON_STATE_TYPE: Record<CommonStateTypes, StateType[]> = {
  OFFLINE: [ StateType.offline ],
  ONLINE: [ StateType.normalSpeed, StateType.overSpeed, StateType.lowSpeed ],
  PARKING: [ StateType.online, StateType.parking, StateType.stop ]
}

