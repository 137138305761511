export function isEmptyRect(rect: ClientRect): boolean {
  if (!rect) {
    return true
  }

  return rect.left === 0 &&
    rect.top === 0 &&
    rect.height === 0 &&
    rect.width === 0 &&
    rect.right === 0 &&
    rect.bottom === 0
}
