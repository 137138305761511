export interface ScreenSize {
  height: number
  width: number
}

/**
 * Перечисление всех возможных ключей для хранения состояния приложения
 */
export enum BrowserStorageVariables {
  /**
   * Ключ организации
   *
   * @example X8X8X8X8
   * @see {Org#key}
   */
  orgKey = "orgKey",

  /**
   * Токен доступа
   */
  accessToken = "accessToken",

  /**
   * Токен обновления
   */
  refreshToken = "refreshToken",

  /**
   * Идентификатор организации
   */
  orgId = "orgId",

  /**
   * Идентификатор пользователя
   */
  uid = "uid",

  /**
   * Флаг анимации маркеров
   */
  markerAnimations = "markerAnimations",

  /**
   * Флаг подписи маркеров
   */
  markersTooltip = "markersTooltip",

  /**
   * Флаг подписи геозоны
   */
  zonesTooltip = "zonesTooltip",

  /**
   * Флаг слежения за продвижением трека
   */
  bindTrackMarkerPositionInMap = "bindTrackMarkerPositionInMap",

  /**
   * Флаг активации кластеризации маркеров
   */
  enableMarkerClusterization = "enableMarkerClusterization",

  /**
   * Флаг автофокуса маркеров и геозон
   */
  enableSelectionMapAutofocus = "enableSelectionMapAutofocus",


  /**
   * Флаг изменения события для открытия карточки трека
   */
  selectMarkerByMouseover = "selectMarkerByMouseover",

  /**
   * Флаг отображения цветов маркеров по состоянию
   */
  markerColorByState = "markerColorByState",

  /**
   * Флаг адаптивности скорости трека
   */
  adaptiveSpeedInTrack = "adaptiveSpeedInTrack",

  /**
   * Флаг эксперементальных возможностей
   */
  experimentalFeatures = "experimentalFeatures",

  /**
   * Флаг активности темной темы
   */
  appearanceTheme = "appearanceTheme",

  /**
   * Флаг активности гирлянды
   */
  showGarland = "showGarland",

  /**
   * Флаг запоминания пользователя
   */
  rememberMe = "rememberMe",

  /**
   * Список развернутых узлов
   */
  openTreeNode = "openTreeNode",

  /**
   * Список скрытых узлов
   */
  hiddenTreeNodes = "hiddenTreeNodes",

  /**
   * Состояние корневого узла
   */
  rootState = "root_state",

  /**
   * Позиция скролла дерева
   */
  scrollState = "scrollState",

  /**
   * Тип дерева
   * @see {TreeType}
   */
  treeType = "treeType",

  /**
   * Флаг плоского вида дерева
   */
  flatTree = "flatTree",

  /**
   * Стратегия отображения названия объекта
   * @see {TrackingObjectNamingStrategy}
   */
  trackingObjectNamingStrategy = "trackingObjectNamingStrategy",

  /**
   * @deprecated
   */
  version = "version",

  /**
   * Название выбранной карты
   */
  mapName = "mapName",

  /**
   * Позиция карты
   */
  mapPosition = "mapPosition",

  /**
   * Пользовательский фингерпринт
   */
  did = "did",

  printViewFontSize = "printViewFontSize",
  printViewFontFamily = "printViewFontFamily",
  printViewMonoColor = "printViewMonoColor",

  /**
   * Выбранный язык
   */
  language = "language",

  /**
   * Флаг установлено ли как PWA
   */
  isPwaInstalled = "isPwaInstalled",

  /**
   * Флаг отображения информации о семинаре
   */
  isSeminarRecordNotificationViewed = "isSeminarRecordNotificationViewed",

  /**
   * Отображение признака «Требуется ТО» рядом с ТС в дереве
   */
  showNeedMaintenanceIcon = "showNeedMaintenanceIcon",

  /**
   * Сохранение настроек меню
   */
  settingMenu = "settingMenu",

  /**
   * Показывать модель вместо рег. номера
   */
  showModel = "showModel"
}

/**
 * Словарь
 * @TODO: Добавить возможность указывать тип ключа
 */
export interface Dictionary<T> {
  [ key: string ]: T
}

export function toDictionary<T>(array: T[], takeFieldKey: (item?: T) => string): Dictionary<T> {
  const result: Dictionary<T> = {}

  for (const item of array) {
    const resultKey: string = takeFieldKey(item)
    result[ resultKey ] = item
  }

  return result
}
