const NORTH_EAST_DIRECTION: number = 45
const EAST_DIRECTION: number = 90
const SOUTH_EAST_DIRECTION: number = 135
const SOUTH_DIRECTION: number = 180
const SOUTH_WEST_DIRECTION: number = 225
const WEST_DIRECTION: number = 270
const NORTH_WEST_DIRECTION: number = 315

/** @deprecated use {@see takeCardinalPointTranslateKey} */
export function getDirection(course: number): string {
  // Angle in range [0; 360)
  const directionHalfSection: number = 22.5

  if (course < NORTH_EAST_DIRECTION - directionHalfSection ||
    course > NORTH_WEST_DIRECTION + directionHalfSection) {
    return "Север"
  }
  if (course > NORTH_EAST_DIRECTION - directionHalfSection &&
    course < NORTH_EAST_DIRECTION + directionHalfSection) {
    return "Северо-восток"
  }
  if (course > EAST_DIRECTION - directionHalfSection &&
    course < EAST_DIRECTION + directionHalfSection) {
    return "Восток"
  }
  if (course > SOUTH_EAST_DIRECTION - directionHalfSection &&
    course < SOUTH_EAST_DIRECTION + directionHalfSection) {
    return "Юго-восток"
  }
  if (course > SOUTH_DIRECTION - directionHalfSection &&
    course < SOUTH_DIRECTION + directionHalfSection) {
    return "Юг"
  }
  if (course > SOUTH_WEST_DIRECTION - directionHalfSection &&
    course < SOUTH_WEST_DIRECTION + directionHalfSection) {
    return "Юго-запад"
  }
  if (course > WEST_DIRECTION - directionHalfSection &&
    course < WEST_DIRECTION + directionHalfSection) {
    return "Запад"
  }
  if (course > NORTH_WEST_DIRECTION - directionHalfSection &&
    course < NORTH_WEST_DIRECTION + directionHalfSection) {
    return "Северо-запад"
  }
  return "Неизвестно"
}
