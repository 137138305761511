import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { TranslateModule } from "@ngx-translate/core"
import { SharedServicesModule } from "./shared-services"
import { PipeModule } from "./pipe/pipe.module"

@NgModule({
  imports: [
    CommonModule,
    SharedServicesModule.forRoot(),
    PipeModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipeModule,
    SharedServicesModule
  ],
  declarations: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {
}
