// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import packageJson from "../../../package.json"

export const environment: { versionCodeName: string, production: boolean, appName: string, version: string } = {
  version: packageJson.version,
  versionCodeName: packageJson.versionCodeName,
  production: true,
  appName: packageJson.name
}
