import * as fromActions from "../actions"
import { initialUserState, UserState } from "../model/user"
import { updateManyField, updateOneField } from "../../../../global/shared/utils/reducers"

export function reducer(state: UserState = initialUserState, action: any): any {
  switch (action.type) {
    case fromActions.LOAD_USER_DATA_COMPLETE:
      return updateManyField(state, action.payload)
    case fromActions.CHANGE_LOAD_PROFILE_STATE:
      return updateOneField(action.payload, "loading", state)
    default:
      return state
  }
}
