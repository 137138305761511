import { fromEvent, Observable } from "rxjs"
import { Action } from "pmk/base/actions"
import { filter, map } from "rxjs/operators"
import { isArrayBuffer, isEmpty } from "../data"
import { Serializer } from "./model"
import { EmptySerializer } from "./emptySerializer"

export class MessageSender {
  constructor(private context: Window | Worker, private serializer: Serializer<any, any> = new EmptySerializer()) {}

  public send(action: Action<any>): void {
    if (!this.serializer.serializable(action)) {
      throw new Error("impossible serialize action")
    }

    const data: any = this.serializer.serialize(action)
    if (isArrayBuffer(data)) {
      return this.postMessage(data, [ data ])
    }

    return this.postMessage(data)
  }

  public messages(): Observable<Action<any>> {
    return fromEvent(this.context, "message").pipe(
      map((event: any) => event.data),
      filter((data) => this.serializer.deserializable(data)),
      map((data) => this.serializer.deserialize(data)),
      filter((action: Action<any>) => !isEmpty(action.type))
    )
  }

  private postMessage(data: any, transfer: Transferable[] = []): void {
    return this.context instanceof Worker ?
      this.context.postMessage(data, transfer) :
      this.context.postMessage(data, "*", transfer)
  }
}

