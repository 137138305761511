import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store"
import { CoreFlags, CoreState, RootState, WindowMode } from "../model"
import { AppearanceTheme } from "pmk/ui/layout/theme-layout"

export const selectFeatureAppState: MemoizedSelector<RootState, CoreState> = createFeatureSelector<CoreState>("app")

export const selectWindowMode: MemoizedSelector<RootState, WindowMode> = createSelector(selectFeatureAppState, (app: CoreState) => app.windowMode)

export const selectTheme: MemoizedSelector<RootState, AppearanceTheme> = createSelector(selectFeatureAppState, (app: CoreState) => app.appearanceTheme)
export const selectMaintenanceRedZoneObjectCount: MemoizedSelector<RootState, number> = createSelector(selectFeatureAppState, (app: CoreState) => {
  return app.maintenanceRedZoneObjectCount
})

export function takeSettingSelector<T extends keyof CoreFlags>(field: T): MemoizedSelector<RootState, CoreFlags[ T ]> {
  /**
   * @todo Починить типы
   * */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return createSelector(selectFeatureAppState, (data: CoreState) => data[ field ])
}
