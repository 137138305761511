let nextHandle: number = 1

const tasksByHandle: { [ handle: string ]: () => void } = {}

function runIfPresent(handle: number): void {
  const cb: () => void = tasksByHandle[ handle ]

  if (cb) {
    try {
      cb()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    }
  }
  clearImmediate(handle)
}

/** @deprecated */
export function setImmediate(cb: () => void): number {
  const handle: number = nextHandle++
  tasksByHandle[ handle ] = cb
  Promise.resolve().then(() => runIfPresent(handle))
  return handle
}

export function clearImmediate(handle: number): void {
  delete tasksByHandle[ handle ]
}
