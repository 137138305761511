import { Injectable } from "@angular/core"
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Observable } from "rxjs"
import { select, Store } from "@ngrx/store"
import { BrowserStorageService } from "pmk/shared/shared-services"
import { BrowserStorageVariables } from "pmk/shared"
import { selectFeatureUserState } from "../../../web-navigator/app/core/selectors"
import { UserRole, UserState } from "../../../web-navigator/app/core/model/user"
import { map, take, tap } from "rxjs/operators"
import { RootState } from "../../../web-navigator/app/core/model"

@Injectable()
export class LogoutInterceptor implements HttpInterceptor {
  constructor(private store: Store<RootState>,
              private browserStore: BrowserStorageService) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const localStorageOrgId: string = this.browserStore.onGet(BrowserStorageVariables.orgId)

    this.store.pipe(
      select(selectFeatureUserState),
      take(1),
      map((selectFeatureUserState: UserState) => {
        if (selectFeatureUserState.role === UserRole.user ||
          selectFeatureUserState.role === UserRole.superUser) {
          if (typeof selectFeatureUserState.orgId === "undefined") {
            return true
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line eqeqeq
          return localStorageOrgId == selectFeatureUserState.orgId
        }

        return true
      }),
      tap((isOrgIdEquals: boolean) => {
        if (!isOrgIdEquals) {
          window.location.reload()
        }
      })
    ).subscribe()

    return next.handle(request)
  }
}
