
export interface PlatformInfo {
  screen: string
  browser: Browser
  browserVersion: string
  browserMajorVersion: number
  mobile: boolean
  os: string
  osVersion: string
  cookies: boolean
  x: string
  y: string
  userAgent: string
  hardwareConcurrency: number
  deviceMemory: number
  networkType: string
  platform: string
  touchScreen: boolean
  useGPU: boolean
  gpuRenderer: string
}

enum Browser {
  opera = "Opera",
  edge = "Microsoft Edge",
  msie = "Microsoft Internet Explorer",
  chrome = "Chrome",
  safari = "Safari",
  firefox = "Firefox",
  trident = "Microsoft Internet Explorer"
}

// eslint-disable-next-line no-var
export var platform: PlatformInfo = getPlatformInfo()
export const goToMobileSize: number = 900

export function isMobileMode(): boolean {
  return window.innerWidth < goToMobileSize
}

export function getPlatformInfo(): PlatformInfo {
  if (platform !== undefined && platform !== null) {
    return platform
  }

  const unknown: any = "-"
  let width: any
  let height: any

  // screen
  let screenSize: string = ""
  if (screen.width) {
    width = (screen.width) ? screen.width : ""
    height = (screen.height) ? screen.height : ""
    screenSize += "" + width + " x " + height
  }

  // browser
  const nVer: string = navigator.appVersion
  const nAgt: string = navigator.userAgent
  let browser: string = navigator.appName
  let version: string = "" + parseFloat(navigator.appVersion)
  let majorVersion: number = parseInt(navigator.appVersion, 10)
  let nameOffset: any
  let verOffset: any
  let ix: any

  // Opera
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browser = "Opera"
    version = nAgt.substring(verOffset + 6)
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }
  }
  else if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
    browser = "Microsoft Edge"
    version = nAgt.substring(verOffset + 5)
  }
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browser = "Chrome"
    version = nAgt.substring(verOffset + 7)
  }
  // Safari
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browser = "Safari"
    version = nAgt.substring(verOffset + 7)
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browser = "Firefox"
    version = nAgt.substring(verOffset + 8)
  }
  else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
    browser = nAgt.substring(nameOffset, verOffset)
    version = nAgt.substring(verOffset + 1)
    // eslint-disable-next-line eqeqeq
    if (browser.toLowerCase() == browser.toUpperCase()) {
      browser = navigator.appName
    }
  }
  // trim the version string
  if ((ix = version.indexOf(";")) !== -1) {
    version = version.substring(0, ix)
  }

  if ((ix = version.indexOf(" ")) !== -1) {
    version = version.substring(0, ix)
  }

  if ((ix = version.indexOf(")")) !== -1) {
    version = version.substring(0, ix)
  }

  majorVersion = parseInt("" + version, 10)
  if (isNaN(majorVersion)) {
    version = "" + parseFloat(navigator.appVersion)
    majorVersion = parseInt(navigator.appVersion, 10)
  }

  // mobile version
  const mobile: boolean = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer)

  // cookie
  let cookieEnabled: boolean = (navigator.cookieEnabled)

  if (typeof navigator.cookieEnabled === "undefined" && !cookieEnabled) {
    document.cookie = "testcookie"
    cookieEnabled = (document.cookie.indexOf("testcookie") !== -1)
  }

  // system
  let os: any = unknown
  const clientStrings: any[] = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Android", r: /Android/ },
    { s: "Linux", r: /(Linux|X11)/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ }
  ]
  for (const id in clientStrings) {
    const cs: any = clientStrings[ id ]
    if (cs.r.test(nAgt)) {
      os = cs.s
      break
    }
  }

  let osVersion: any = unknown

  if (/Windows/.test(os)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    osVersion = /Windows (.*)/.exec(os)[ 1 ]
    os = "Windows"
  }

  switch (os) {
    case "Mac OS X":
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      osVersion = /Mac OS X ([\.\_\d]+)/.exec(nAgt)[ 1 ]
      break

    case "Android":
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[ 1 ]
      break

    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nAgt)
      osVersion = osVersion[ 1 ] + "." + osVersion[ 2 ] + "." + (osVersion[ 3 ] | 0)
      break
  }

  let touch: boolean = false
  try {
    document.createEvent("TouchEvent")
    touch = true
  } catch (e) {
    touch = false
  }

  // gpu info
  const canvas: HTMLCanvasElement = document.createElement("canvas")
  let gl: any
  let useGPU: any = false
  try {
    gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl")
    useGPU = true
  } catch (e) {}

  let gpuRenderer: any
  if (gl) {
    const debugInfo: any = gl.getExtension("WEBGL_debug_renderer_info")
    gpuRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  }

  return {
    gpuRenderer,
    useGPU,
    x: width as any,
    y: height as any,
    screen: screenSize,
    browser: <Browser>browser,
    browserVersion: version,
    browserMajorVersion: majorVersion,
    mobile: mobile,
    os: os,
    osVersion: osVersion,
    cookies: cookieEnabled,
    userAgent: window.navigator.userAgent,
    hardwareConcurrency: navigator.hardwareConcurrency,
    deviceMemory: (navigator as any).deviceMemory || NaN,
    networkType: (navigator as any).connection ? (navigator as any).connection : NaN,
    platform: navigator.platform,
    touchScreen: touch
  }
}

export function isWindowsXp(): boolean {
  return /(XP)/.test(platform.osVersion)
}

export function isSafari(): boolean {
  return /(Safari)/.test(platform.browser)
}

export function isChrome(): boolean {
  return platform.browser === Browser.chrome
}

export function isFirefox(): boolean {
  return platform.browser === Browser.firefox
}

export function isMobile(): boolean {
  return platform.mobile
}

export function isTouchScreen(): boolean {
  return platform.touchScreen
}

export function isAndroid(): boolean {
  return /(Android)/.test(platform.os)
}

export function isIphone(): boolean {
  return /(iOS)/.test(platform.os)
}

export function isSafariIOS(): boolean {
  return isSafari() && isIphone()
}

export function isIphone5(): boolean {
  return isIphone() && platform.screen === "320 x 568"
}

export function hardwareConcurrency(): number {
  return platform.hardwareConcurrency || 1
}

export function deviceMemory(): number {
  return platform.deviceMemory || 0
}

export function slowDevice(): boolean {
  if (isMobile()) {
    if (isIphone()) {
      return isIphone5()
    }

    if (isAndroid()) {
      return hardwareConcurrency() < 4 && deviceMemory() < 3
    }

    return true
  }

  return hardwareConcurrency() <= 2 && deviceMemory() <= 2
}
