import { AppearanceTheme } from "pmk/ui/layout/theme-layout"
import { ScreenSize } from "../../../../global/shared/model"
import { WindowMode } from "./window-mode"

export interface CoreFlags {
  appearanceTheme: AppearanceTheme
  markerAnimations: boolean
  markersTooltip: boolean
  zonesTooltip: boolean
  bindTrackMarkerPositionInMap: boolean
  enableMarkerClusterization: boolean
  enableSelectionMapAutofocus: boolean
  selectMarkerByMouseover: boolean
  markerColorByState: boolean
  adaptiveSpeedInTrack: boolean
  experimentalFeatures: boolean
  showNeedMaintenanceIcon: boolean
  showModel: boolean
  showGarland: boolean
}

export interface CoreState extends CoreFlags {
  windowMode: WindowMode
  /**
   * @deprecated Не используется
   */
  screenSize: ScreenSize
  maintenanceRedZoneObjectCount: number
}

export enum Language {
  ru = "ru",
  en = "en"
}

export enum ReportMode {
  build = "BUILD",
  schedule = "SCHEDULE"
}

/**
 * Режим просмотра модуля
 */
export enum ModuleMode {
  read = "READ",
  edit = "EDIT"
}

export const initialCoreState: CoreState = {
  windowMode: WindowMode.map,
  appearanceTheme: AppearanceTheme.dark,
  markerAnimations: false,
  markersTooltip: false,
  zonesTooltip: false,
  bindTrackMarkerPositionInMap: false,
  enableMarkerClusterization: true,
  enableSelectionMapAutofocus: true,
  selectMarkerByMouseover: false,
  markerColorByState: false,
  experimentalFeatures: false,
  adaptiveSpeedInTrack: true,
  showNeedMaintenanceIcon: false,
  showModel: false,
  /**
   * FIXME: Обращение к глобальным объектам, нужно выносить
   * в компоненты или туда где это можно замокать.
   */
  screenSize: { width: window.innerWidth, height: window.innerHeight },
  maintenanceRedZoneObjectCount: null as any,
  showGarland: true
}
