import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { ErrorHandler, Injectable } from "@angular/core"
import { Store } from "@ngrx/store"
import { Observable, of, Subject, throwError } from "rxjs"

import { catchError, switchMap, take, tap } from "rxjs/operators"
import { AuthService } from "../../../web-navigator/app/auth/services/auth.service"
import * as fromActions from "../../../web-navigator/app/core/actions"
import { RootState } from "../../../web-navigator/app/core/model"
import { authPath } from "../constants"
import { BrowserStorageVariables } from "../model"
import { BrowserStorageService } from "../shared-services/browser-storage.service"

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private browserStorage: BrowserStorageService,
              private errorHandler: ErrorHandler,
              private store$: Store<RootState>,
              private authService: AuthService) {
  }

  /**
   * Флаг говорящий о том что запрос на получение нового access токена в процессе
   */
  private isGetAccessTokenGetInProgress: boolean = false

  private isLogoutInProgress: boolean = false

  /**
   * Уведомитель, который сообщает что получен новый access токен
   */
  private accessToken$: Subject<void> = new Subject<void>()

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.getRequest(req, next).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.url === null) {
          return throwError(error)
        }

        /**
         * Если упавший запрос это запрос на сервис авторизации,
         * значить сгорел refresh токен, нужно выкинуть его
         * на страницу авторизации
         */
        if (error.url.includes(authPath)) {
          this.isLogoutInProgress = true
          this.isGetAccessTokenGetInProgress = false
          this.store$.dispatch(fromActions.destroyApp())
          return of(null)
        }

        if (this.isLogoutInProgress) {
          return of(null)
        }

        /**
         * Если код ошибки не 401, значить что-то не так,
         * никак не связанное с авторизацией.
         * Просто прокидываем ошибку дальше
         */
        if (error.status !== 401) {
          this.errorHandler.handleError(error)
          return throwError(error)
        }

        /**
         * Если получение нового access в процессе,
         * то кидаем пришедший запрос в очередь ожидания
         */
        if (this.isGetAccessTokenGetInProgress) {
          return this.accessToken$.pipe(
            take(1),
            switchMap(() => this.getRequest(req, next))
          )
        }

        /**
         * Если код добрался сюда, пройдя все проверки,
         * то это означает что именно на этом запросе сгорел access токен,
         * так что мы говорим что получение в процессе и идем на сервер
         * авторизации за новым
         */
        this.isGetAccessTokenGetInProgress = true

        /**
         * Достаем refresh токен
         */
        const refreshToken: string | null = this.browserStorage.onGet(BrowserStorageVariables.refreshToken)
        const orgKey: string | null = this.browserStorage.onGet(BrowserStorageVariables.orgKey)

        if (refreshToken === null || orgKey === null) {
          this.store$.dispatch(fromActions.destroyApp())
          return of(null)
        }

        return this.authService.authorizeByRefresh({ refreshToken, orgKey }).pipe(
          tap((v) => {
            /**
             * Сохраняем новые refresh и access токены
             */
            this.browserStorage.onSet(BrowserStorageVariables.accessToken, v.access_token)

            /**
             * Говорим очереди всех запросов, что теперь можно
             * выполняться
             */
            this.accessToken$.next()
            this.isGetAccessTokenGetInProgress = false
          }),
          switchMap(() => {
            /**
             * Выполняем, тот самый запрос на котором
             * мы впервые узнали что токен сгорел
             */
            return this.getRequest(req, next)
          })
        )
      })
    )
  }

  private getRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string | null = this.browserStorage.onGet(BrowserStorageVariables.accessToken)

    if (accessToken === null) {
      return next.handle(req)
    }

    return next.handle(req.clone({ headers: req.headers.set("Authorization", `Bearer ${ accessToken }`) }))
  }
}
