import { NgbDate } from "@ng-bootstrap/ng-bootstrap"

export const timeRange: any = (dates: Date[], models: Model[]) => {
  const daysStorage: Date[] = []
  const intervals: ({ from: any, to: any })[] = []

  if (!models || !models.length) {
    return [ {
      from: dates[ 0 ].toISOString(),
      to: dates[ 1 ].toISOString()
    } ]
  }

  if (models.length === 1 && models[ 0 ].timeRange.start === 0 && models[ 0 ].timeRange.end === 24 &&
    models[ 0 ].dayWeek[ 0 ] === 1 && models[ 0 ].dayWeek[ 1 ] === 0) {
    return [ {
      from: dates[ 0 ].toISOString(),
      to: dates[ 1 ].toISOString()
    } ]
  }

  for (let day: number = dates[ 0 ].getTime(); day <= dates[ 1 ].getTime(); day += 3600000) {
    daysStorage.push(new Date(day))
  }

  daysStorage.map((day) =>
    models.forEach((model) => {
      if (model.dayWeek[ 0 ] === day.getDay() && model.timeRange.start === day.getHours()) {
        const end: number = model.timeRange.end === 24 ?
          new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59, 59).getTime() :
          new Date(day.getFullYear(), day.getMonth(), day.getDate(), model.timeRange.end).getTime()
        intervals.push({
          from: day.toISOString(),
          to: new Date(end + 86400000 * ((model.dayWeek[ 1 ] === 0 ? 7 : model.dayWeek[ 1 ]) - model.dayWeek[ 0 ]))
            .toISOString()
        })
      }
    })
  )

  return intervals
}

export function dateToBs(date: Date): NgbDate {
  return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
}

export interface Model {
  dayWeek: number[]
  timeRange: TimeRange
}

export interface TimeRange {
  start: number
  end: number
}
