/** Обновляет поле state, по условию, и вызвращает новый state
 * @param payload: Action.payload новое значение
 * @param field: string обновляемое поле
 * @param state: State стейт
 * @param callback: (payload, state) => boolean условие обновления
 * @return new state
 * */
export function updateOneField(payload: any, field: any, state: any, callback: (payload: any, state: any) => boolean = () => true): any {
  if (callback(payload, state)) {
    return {
      ...state,
      [ field ]: payload
    }
  }
  return state
}

export function updateManyField(state: any, fields: Fields): any {
  for (const key in fields) {
    state[ key ] = fields[ key ]
  }
  return { ...state }
}

export interface Fields {
  [ fieldName: string ]: any
}
