import { DOCUMENT } from "@angular/common"
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core"

@Injectable({
  providedIn: "root"
})
export class FaviconService {
  private renderer: Renderer2
  private readonly faviconLinkElement: HTMLLinkElement

  constructor(@Inject(DOCUMENT) private document: Document,
              rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null)
    const faviconLink: HTMLLinkElement | null = this.document.querySelector("link#favicon")

    if (faviconLink === null) {
      throw new TypeError("Favicon element not found")
    }

    this.faviconLinkElement = faviconLink
  }

  public setHref(value: string): void {
    this.renderer.setAttribute(
      this.faviconLinkElement,
      "href",
      value
    )
  }
}
