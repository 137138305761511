import { colorsArray, extraIgnColorNames } from "../../../global/shared/constants"
import { HEX_COLOR } from "@waliot/web-sdk"

export function getColorTrack(index: number): string {
  return colorsArray[ index % colorsArray.length ]
}

export function getColorFromExtraIgnColorMap(): string {
  /**
   * TODO: Если понадобится функциональность многоцветности треков в режиме трека "Вторые моточасы" нужно:
   * 1. Удалить `return extraIgnColorNames[ 0 ]` и расскоментировать `return extraIgnColorNames[ index % extraIgnColorNames.length ]`
   * 2. Добавить новые маркеры начала и конца трека с новыми цветами
   */
  return extraIgnColorNames[ 0 ]
  // return extraIgnColorNames[ index % extraIgnColorNames.length ]
}

export interface RgbColor {
  red: number
  green: number
  blue: number
}

/**
 * Конвертирует цвет в Hex формате в RGB
 * @param hexColor Цвет в Hex формате с ведушим хэшем(#)
 * @returns Объект содержащий целочисленные значения RGB цвета
 */
export function hexToRgb(hexColor: HEX_COLOR): RgbColor {
  if (!/^#([a-fA-F0-9]{6})$/.test(hexColor)) {
    throw new Error(`Invalid hex string: ${ hexColor }`)
  }

  return {
    red: parseInt(hexColor.substr(1, 2), 16),
    green: parseInt(hexColor.substr(3, 2), 16),
    blue: parseInt(hexColor.substr(5, 2), 16)
  }
}

/**
 * Рассчитывает контраcтность цвета по [YIQ]{@link https://en.wikipedia.org/wiki/YIQ} модели
 *
 * Чем больше значение YIQ, тем более яркий цвет. То есть если цвет фона больше 128 единиц,
 * то можно менять цвет на противоположный фону, то есть на более темный и наоборот.
 *
 * @see https://24ways.org/2010/calculating-color-contrast/
 * @param hexColor Цвет в HEX формате
 * @returns Целое число от 0 до 255
 */
export function calcYiqColorContrast(hexColor: HEX_COLOR): number {
  const { red, blue, green } = hexToRgb(hexColor)
  return Math.round(((red * 299) + (blue * 587) + (green * 114)) / 1000)
}
