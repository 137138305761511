import { isEmpty, isEmptyString } from "./data"

/**
 * Округляет число до определенного количества цифр после запятой
 *
 * @param num число которое нужно округлить
 * @param of количество цифр после запятой
 */
export function roundOfNumber(num: number, of: number): number {
  const numbersAfterPoint: number = 10 ** of
  return Math.round(num * numbersAfterPoint) / numbersAfterPoint
}

/**
 * Переводит количество километров которое нужно отнять/прибавить от/к 100 км в пропорцию
 * ```
 * getMileageCorrectionFromKm(10) // 1.1
 * getMileageCorrectionFromKm(-10) // 0.9
 * ```
 *
 * @param km
 */
export function getMileageCorrectionFromKm(km: number): number {
  return 1 + roundOfNumber((km / 100), 3)
}

/**
 * Переводит количество километров в пропорцию
 * ```
 * getKmFromMileageCorrection(1.2) // 20
 * getMileageCorrectionFromKm(0.3) // -70
 * ```
 *
 * @param ratio
 */
export function getKmFromMileageCorrection(ratio: number): number {
  return roundOfNumber((ratio - 1) * 100, 3)
}

/**
 * Отдает меньшее число из массива игнорируя NaN, Null, Undefined и пустую строку
 * ```
 * getMinimalNumber([ 1, 2, 3, NaN, undefined, '' ]) // 1
 * ```
 *
 * @param nums
 */

export function getMinimalNumber(nums: number[]): number {
  const numsWithoutNaN: number[] = nums.filter((num) => !(isNaN(num) || isEmpty(num) || isEmptyString(num)))
  return Math.min(...numsWithoutNaN)
}

/**
 * Определяет находится ли число внутри множества (включительно)
 *
 * @param num Искомое число
 * @param range Множество чисел
 * @return boolean
 */
export function isBetween(num: number, range: [ number, number ]): boolean {
  const [ start, end ] = range
  return num >= start && num <= end
}
