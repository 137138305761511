export function getCourse(center: [ number, number ], point: [ number, number ]): number {
  const toRad: (deg: any) => number = (deg) => deg * Math.PI / 180
  const toDeg: (rad: any) => number = (rad) => rad * 180 / Math.PI

  const cLat: number = toRad(center[ 0 ])
  const cLng: number = toRad(center[ 1 ])
  const pLat: number = toRad(point[ 0 ])
  const pLng: number = toRad(point[ 1 ])
  const y: number = Math.sin(pLng - cLng) * Math.cos(pLat)
  const x: number = Math.cos(cLat) * Math.sin(pLat) - Math.sin(cLat) * Math.cos(pLat) * Math.cos(pLng - cLng)
  const brng: number = toDeg(Math.atan2(y, x))
  return (brng + 360) % 360
}

export function distanceTo(pointA: [ number, number ], pointB: [ number, number ]): number {
  const x: number = pointA[ 0 ] - pointB[ 0 ]
  const y: number = pointA[ 1 ] - pointB[ 1 ]
  return Math.sqrt(x * x + y * y)
}

export function interpolatePosition(
  p1: [ number, number ],
  p2: [ number, number ],
  travelTime: number, timestamp: number): [ number, number ] {
  let k: number = timestamp / travelTime
  k = (k > 0) ? k : 0
  k = (k > 1) ? 1 : k
  return [ p1[ 0 ] + k * (p2[ 0 ] - p1[ 0 ]), p1[ 1 ] + k * (p2[ 1 ] - p1[ 1 ]) ]
}
