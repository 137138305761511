import { Action, createAction } from "@ngrx/store"
import { UserState } from "../model/user"
import { LoadingState } from "../../../../global/shared/model"

export const logout: any = createAction("[Core] logout")

export const LOAD_USER_DATA: string = "[Core] load user data"
export const LOAD_USER_DATA_COMPLETE: string = "[Core] load user data complete"
export const CHANGE_LOAD_PROFILE_STATE: string = "[Core] change load profile state"
export const SAVE_PROFILE: string = "[Core] save profile"

export class LoadUserData implements Action {
  public readonly type: string = LOAD_USER_DATA

  constructor(public payload: { uid: number, orgId: number }) {
  }
}

export class LoadUserDataComplete implements Action {
  public readonly type: string = LOAD_USER_DATA_COMPLETE

  constructor(public payload: UserState) {
  }
}

export class ChangeLoadProfileState implements Action {
  public readonly type: string = CHANGE_LOAD_PROFILE_STATE

  constructor(public payload: LoadingState) {
  }
}

export class SaveProfile implements Action {
  public readonly type: string = SAVE_PROFILE

  constructor(public payload: UserState) {
  }
}

export type ActionUser =
  | LoadUserData
  | LoadUserDataComplete
  | ChangeLoadProfileState
  | SaveProfile
