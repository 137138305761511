import { requestAnimFrame } from "./requestAnimFrame"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const win: any = window

// eslint-disable-next-line @typescript-eslint/ban-types
export const requestTimeout: any = (fn: Function, delay: number) => {
  if (!win.requestAnimationFrame &&
    !win.webkitRequestAnimationFrame &&
    !(win.mozRequestAnimationFrame && win.mozCancelRequestAnimationFrame) &&
    !win.oRequestAnimationFrame &&
    !win.msRequestAnimationFrame) {
    return win.setTimeout(fn, delay)
  }

  const start: number = new Date().getTime(),
    handle: any = {}

  function loop(): void {
    const current: number = new Date().getTime()
    const delta: number = current - start

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delta >= delay ? fn.call() : handle.value = requestAnimFrame(loop)
  }

  handle.value = requestAnimFrame(loop)
  return handle
}
