import { InjectionToken } from "@angular/core"

export const GEO_LOCATION: InjectionToken<Geolocation> = new InjectionToken<Geolocation>("GEO_LOCATION", {
  providedIn: "root",
  factory: () => navigator.geolocation
})

export const NAVIGATOR: InjectionToken<Navigator> = new InjectionToken("NAVIGATOR", {
  providedIn: "root",
  factory: () => navigator
})

export const WINDOW: InjectionToken<Window> = new InjectionToken("WINDOW", {
  providedIn: "root",
  factory: () => window
})
