import { Injectable } from "@angular/core"
import { LocalStorageService, SessionStorageService } from "ngx-webstorage"
import { BrowserStorageVariables } from "../model"

@Injectable({ providedIn: "root" })
/**
 * @TODO: Добавить типизацию для методов
 */
export class BrowserStorageService {
  public longStorage: boolean = false

  constructor(public $ls: LocalStorageService,
              public $ss: SessionStorageService) {
  }

  private onCheckStorage(): void {
    if (this.$ls.retrieve(BrowserStorageVariables.accessToken)) {
      this.longStorage = true
      return
    }

    this.longStorage = false
  }

  public onSet(key: BrowserStorageVariables, data: any, longStorage?: boolean): any {
    this.onCheckStorage()
    if (this.longStorage || longStorage) {
      return this.$ls.store(key, data)
    }
    return this.$ss.store(key, data)
  }

  public onSetAll(key: string, data: string): void {
    this.onCheckStorage()
    this.$ls.store(key, data)
    this.$ss.store(key, data)
  }

  public onSetToLs(key: string, data: string): any {
    this.onCheckStorage()
    return this.$ls.store(key, data)
  }

  public onSetToSs(key: string, data: string): any {
    this.onCheckStorage()
    return this.$ss.store(key, data)
  }

  public onGet(key: BrowserStorageVariables): any {
    this.onCheckStorage()

    if (this.longStorage) {
      return this.$ls.retrieve(key)
    }

    return this.$ss.retrieve(key)
  }

  public onClear(key?: BrowserStorageVariables): void {
    this.onCheckStorage()
    if (this.longStorage) {
      return this.$ls.clear(key)
    }
    return this.$ss.clear(key)
  }

  public onGetAll(key: string): any {
    return this.$ss.retrieve(key) ?? this.$ls.retrieve(key)
  }

  public onClearEverywhere(key?: string): void {
    this.$ls.clear(key)
    this.$ss.clear(key)
  }
}
