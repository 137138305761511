import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { WaliotExtraHeaderNames, X_WALIOT_APP_NAME, X_WALIOT_APP_VERSION, X_WALIOT_LANGUAGE_HEADER } from "@waliot/web-sdk"
import { BrowserStorageVariables } from "pmk/shared"
import { isNotEmpty } from "pmk/utils"
import { Observable } from "rxjs"
import { Language } from "../../../web-navigator/app/core/model"
import { environment } from "../../../web-navigator/environments/environment"
import { BrowserStorageService } from "../shared-services"

@Injectable()
export class WaliotExtraHeadersInterceptor implements HttpInterceptor {
  constructor(private browserStorageService: BrowserStorageService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lang: any = this.browserStorageService.onGet(BrowserStorageVariables.language)
    const headers: HttpHeaders = request
      .headers
      .set(X_WALIOT_APP_NAME, WaliotExtraHeaderNames.navigator)
      .set(X_WALIOT_APP_VERSION, environment.version)
      .set(X_WALIOT_LANGUAGE_HEADER, isNotEmpty(lang) ? lang : Language.ru)

    return next.handle(request.clone({ headers }))
  }
}
