import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Observable } from "rxjs"
import { authUrl } from "../../../../global/shared/constants"
import { BrowserStorageVariables } from "../../../../global/shared/model"
import { BrowserStorageService } from "../../../../global/shared/shared-services"
import { AuthorizationPayload, RefreshTokenCredentials, UsernameCredentials } from "../models/auth"

@Injectable()
export class AuthService {
  constructor(private browserStorage: BrowserStorageService,
              private http: HttpClient,
              private router: Router) {
  }

  public login(credentials: UsernameCredentials): Observable<AuthorizationPayload> {
    return this.authorizeByPass(credentials)
  }

  private authorizeByPass(credentials: UsernameCredentials): Observable<AuthorizationPayload> {
    const headers: HttpHeaders = new HttpHeaders()
      .append("org_key", credentials.orgKey.toUpperCase().trim())

    const body: any = {
      username: credentials.username.trim(),
      password: credentials.password.trim(),
      grant_type: "password",
      client_id: "web"
    }

    return this.http.post<AuthorizationPayload>(`${ authUrl }/token`, body, { headers })
  }

  public authorizeByRefresh(credentials: RefreshTokenCredentials): Observable<AuthorizationPayload> {
    const orgKey: string = credentials.orgKey.toString().toUpperCase()

    return this.http.post<AuthorizationPayload>(`${ authUrl }/token`, {
      grant_type: "refresh_token",
      client_id: "web",
      refresh_token: credentials.refreshToken
    }, {
      headers: new HttpHeaders({ org_key: orgKey })
    })
  }

  public getRememberMe(): boolean {
    const { $ls } = this.browserStorage
    return $ls.retrieve(BrowserStorageVariables.rememberMe) || true
  }

  public setRememberMe(value: boolean): void {
    const { $ls } = this.browserStorage
    $ls.store(BrowserStorageVariables.rememberMe, value)
  }

  public getOrgKey(): any {
    return this.browserStorage.onGetAll(BrowserStorageVariables.orgKey)
  }

  public setOrgKey(orgKey: string): any {
    return this.browserStorage.onSet(BrowserStorageVariables.orgKey, orgKey)
  }

  public setContentApp(orgId: number, uid: number, token: string): void {
    this.browserStorage.longStorage = false
    this.browserStorage.onSet(BrowserStorageVariables.accessToken, token)
    this.browserStorage.onSet(BrowserStorageVariables.orgId, orgId)
    this.browserStorage.onSet(BrowserStorageVariables.uid, uid)
  }

  public setTokens(token: any, setToLs: boolean): void {
    this.browserStorage.longStorage = setToLs
    this.browserStorage.onSet(BrowserStorageVariables.accessToken, token.access_token, setToLs)
    this.browserStorage.onSet(BrowserStorageVariables.refreshToken, token.refresh_token, setToLs)
    this.browserStorage.onSet(BrowserStorageVariables.orgId, token.org_id, setToLs)
    this.browserStorage.onSet(BrowserStorageVariables.uid, token.user_id, setToLs)
  }

  public onCheckIsAuth(): void {
    const token: any = this.browserStorage.onGetAll(BrowserStorageVariables.accessToken)
    if (token) {
      this.onNavigateToApp()
    }
  }

  public onNavigateToApp(): Promise<boolean> {
    return this.router.navigateByUrl("/app")
  }
}
