import { animationFrameScheduler, fromEvent, Observable, Subject } from "rxjs"
import { observeOn, takeUntil, tap } from "rxjs/operators"
import { toKebabCase } from "./text"

const willChangePriority: (keyof typeof CSSStyleDeclaration.prototype)[] = [ "backgroundColor", "background", "color", "transform" ]

class AnimationContext {
  private originStyle: Partial<CSSStyleDeclaration> = {}
  constructor(private el: HTMLElement, private animationStylesName: string[]) {}

  public addTransition(duration: string): void {
    this.setStyle("transition", `${this.animationStylesName.map((value) => `${toKebabCase(value)} ${duration}`).join(",")}`)
  }

  public resetTransition(): void {
    this.resetStyle("transition")
  }

  public addWillChange(): void {
    const fields: any[] = []
    for (let i: number = 0; i < willChangePriority.length; i++) {
      const value: any = willChangePriority[ i ]
      if (this.animationStylesName.includes(value as any)) {
        fields.push(toKebabCase(value as any))
      }
    }
    this.setStyle("will-change" as any, fields.join(","))
  }

  public resetWillChange(): void {
    this.resetStyle("will-change")
  }

  public animateTo(style: Partial<CSSStyleDeclaration>): Observable<this> {
    return new Observable((subscriber) => {
      const destroy$: Subject<void> = new Subject<void>()
      fromEvent(this.el, "transitionend").pipe(
        takeUntil(destroy$),
        tap(() => subscriber.next(this)),
        tap(() => subscriber.complete())
      ).subscribe()
      for (let i: number = 0; i < this.animationStylesName.length; i++) {
        const styleName: any = this.animationStylesName[ i ]
        this.setStyle(styleName, style[ styleName ])
      }
      return () => {
        destroy$.next()
        destroy$.complete()
      }
    })
  }

  private setStyle(styleName: string, value: any): void {
    this.originStyle[ styleName as any ] = this.el.style[ styleName as any ]
    this.el.style[ styleName as any ] = value
  }

  private resetStyle(styleName: any): void {
    this.el.style[ styleName ] = this.originStyle[ styleName as any ] as any
  }
}

export function animatedTransition(targetEl: HTMLElement,
                                   style: Partial<CSSStyleDeclaration>,
                                   duration: string): Observable<void> {
  return new Observable((subscriber) => {
    const destroy$: Subject<void> = new Subject()
    const context: AnimationContext = new AnimationContext(targetEl, Object.keys(style))
    const resetAnimation: () => void = () => {
      context.resetTransition()
      context.resetWillChange()
    }

    const animateTo: Observable<any> = context.animateTo(style).pipe(
      takeUntil(destroy$),
      observeOn(animationFrameScheduler),
      tap(() => resetAnimation()),
      tap(() => subscriber.next()),
      tap(() => subscriber.complete())
    )

    context.addTransition(duration)
    context.addWillChange()
    animateTo.subscribe()

    return () => {
      destroy$.next()
      destroy$.complete()
      resetAnimation()
    }
  })
}
