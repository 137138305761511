import { Action, createAction } from "@ngrx/store"
import { payload } from "../../../../global/shared/rxjs/operators"

/**
 * @deprecated use {@link showSuccessAlert}
 */
export const SUCCESS_ALERT: string = "[Alert] Show success alert"

/**
 * @deprecated use {@link showErrorAlert}
 */
export const ERROR_ALERT: string = "[Alert] Show error alert"

/**
 * @deprecated use {@link showWarningAlert}
 */
export const WARNING_ALERT: string = "[Alert] Show warning alert"

/**
 * @deprecated use {@link showInfoAlert}
 */
export const INFO_ALERT: string = "[Alert] Show info alert"

/**
 * @deprecated use {@link showSuccessAlert}
 */
export class SuccessAlert implements Action {
  public readonly type: string = SUCCESS_ALERT

  constructor(public payload: string) {
  }
}

/**
 * @deprecated use {@link showErrorAlert}
 */
export class ErrorAlert implements Action {
  public readonly type: string = ERROR_ALERT

  constructor(public payload: string) {
  }
}

/**
 * @deprecated use {@link showWarningAlert}
 */
export class WarningAlert implements Action {
  public readonly type: string = WARNING_ALERT

  constructor(public payload: string) {
  }
}

/**
 * @deprecated use {@link showInfoAlert}
 */
export class InfoAlert implements Action {
  public readonly type: string = INFO_ALERT

  constructor(public payload: string) {
  }
}

/* eslint-disable */
export const showSuccessAlert = createAction("[Alert] Show success alert", payload<string>())
export const showErrorAlert = createAction("[Alert] Show error alert", payload<string>())
export const showWarningAlert = createAction("[Alert] Show warning alert", payload<string>())
export const showInfoAlert = createAction("[Alert] Show info alert", payload<string>())
/* eslint-enable */
