import { differenceInDays } from "date-fns"
import { ReportDateInterval } from "../../../web-navigator/app/report/models"

enum Months {
  JAN = 1,
  FEB,
  MAR,
  APR,
  MAY,
  JUN,
  JUL,
  AUG,
  SEP,
  OCT,
  NOV,
  DEC
}

/**
 * @deprecated use {DateFormatService}
 * @param date
 * @see {DateFormatService}
 */
export function getFormatedDate(date: any): string {
  const dateObj: Date = new Date(date)
  return `${ dateObj.getHours() }:${ addNoll(dateObj.getMinutes()) }:${ addNoll(dateObj.getSeconds()) }, ${ addNoll(dateObj.getDate()) }.${ addNoll(dateObj.getMonth() + 1) }.${ dateObj.getFullYear() } г.`
}

function addNoll(datetime: number): string {
  const val: string = datetime.toString()
  return val.length > 1 ? val : `0${ val }`
}

/**
 * Возвращает строку, готовую к переводу
 * @param {number} month номер месяца по порядку 1..12
 */
export function getMonthTranslateKey(month: number): string {
  return "TIME.MONTHS." + Months[ month ]
}

export function getTodayDate(fullDay?: boolean): { from: Date, to: Date } {
  let from: Date = new Date()
  const to: Date = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 23, 59, 59, 59)
  if (fullDay) {
    from = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, 0)
  }
  return { from, to }
}

// todo: tests
export function getTimeZone(date: Date = new Date): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return date.toString().match(/([A-Z]+[\+-][0-9]+)/)[ 1 ]
}

export interface CustomReportDateInterval {
  startPeriod: Date
  endPeriod: Date
  daysOfWeek: number[]
}

export function takeDateIntervals(customReportDateInterval: CustomReportDateInterval): ReportDateInterval[] | null {
  if (customReportDateInterval.daysOfWeek.length === 0) {
    throw new Error("Must choose at least one day of the week")
  }

  if (customReportDateInterval.startPeriod.getTime() > customReportDateInterval.endPeriod.getTime()) {
    throw new Error("Start period must be earlier than end period")
  }

  const startDate: any = {
    year: customReportDateInterval.startPeriod.getFullYear(),
    month: customReportDateInterval.startPeriod.getMonth(),
    date: customReportDateInterval.startPeriod.getDate()
  }

  const endDate: any = {
    year: customReportDateInterval.endPeriod.getFullYear(),
    month: customReportDateInterval.endPeriod.getMonth(),
    date: customReportDateInterval.endPeriod.getDate()
  }

  const startTime: any = {
    hours: customReportDateInterval.startPeriod.getHours(),
    minutes: customReportDateInterval.startPeriod.getMinutes(),
    seconds: customReportDateInterval.startPeriod.getSeconds()
  }

  const endTime: any = {
    hours: customReportDateInterval.endPeriod.getHours(),
    minutes: customReportDateInterval.endPeriod.getMinutes(),
    seconds: customReportDateInterval.endPeriod.getSeconds()
  }

  const days: number = differenceInDays(
    new Date(endDate.year, endDate.month, endDate.date),
    new Date(startDate.year, startDate.month, startDate.date)
  )

  const intervals: ReportDateInterval[] = []

  for (let i: number = 0; i <= days; i++) {
    const startInterval: Date = new Date(
      startDate.year,
      startDate.month,
      startDate.date + i,
      startTime.hours,
      startTime.minutes,
      startTime.seconds
    )

    if (customReportDateInterval.daysOfWeek.includes(startInterval.getDay())) {
      const endInterval: Date = new Date(
        startDate.year,
        startDate.month,
        startDate.date + i,
        endTime.hours,
        endTime.minutes,
        endTime.seconds
      )

      intervals.push({
        from: startInterval.toISOString(),
        to: endInterval.toISOString()
      })
    }
  }

  if (intervals.length === 0) {
    return null
  }

  return intervals
}
