import { Action, createAction } from "@ngrx/store"
import { BrowserStorageVariables } from "pmk/shared"
import { payload } from "../../../../global/shared/rxjs/operators"
import { Org } from "../../tree/models"
import { WindowMode } from "../model"

export const INIT_APP: string = "[Core] init application"
export const RESET_APP: string = "[Core] reset app"

export class InitApp implements Action {
  public readonly type: string = INIT_APP
}

export class ResetApp implements Action {
  public readonly type: string = RESET_APP
}

const CORE_TAG: string = "[Core]"
/* eslint-disable */
export const unloadApp = createAction(`${ CORE_TAG } unload app`)
export const destroyApp = createAction(`${ CORE_TAG } destroy application`)
export const loadOrgData = createAction(`${ CORE_TAG } load org data`, payload<number>())
export const writeOrgData = createAction(`${ CORE_TAG } write org data`, payload<Org>())
export const changeWindowMode = createAction(`${ CORE_TAG } change window mode`, payload<WindowMode>())

export const changeCoreField = createAction(`${ CORE_TAG } change core field`, payload<{
  prop: BrowserStorageVariables
  value: any
}>())

export const showPwaModal = createAction(`${ CORE_TAG } show PWA modal`)

/**
 * ngrx-действие заглушка.
 *
 * NOTE: Не используйте в операторе `ofType`
 */
export const noopAction = createAction("__NOOP_ACTION__")
/* eslint-enable */
