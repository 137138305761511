// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const win: any = window

export const requestAnimFrame: any = (function () {
  return win.requestAnimationFrame ||
    win.webkitRequestAnimationFrame ||
    win.mozRequestAnimationFrame ||
    win.oRequestAnimationFrame ||
    win.msRequestAnimationFrame ||
    function (callback: any) {
      win.setTimeout(callback, 1000 / 60)
    }
})()
