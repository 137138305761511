import { ModuleWithProviders, NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { BrowserStorageService } from "./browser-storage.service"

@NgModule({
  imports: [
    CommonModule
  ]
})
export class SharedServicesModule {
  public static forRoot(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        { provide: BrowserStorageService, useClass: BrowserStorageService }
      ]
    }
  }

  public static forChild(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        { provide: BrowserStorageService, useExisting: BrowserStorageService }
      ]
    }
  }
}
