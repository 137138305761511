import { ExistingProvider, forwardRef, Type } from "@angular/core"
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn } from "@angular/forms"
import { differenceInDays } from "date-fns"
import { isEmpty, isEmptyString } from "./data"

export function createValueAccessor(constructor: Type<ControlValueAccessor>): ExistingProvider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => constructor),
    multi: true
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CustomValidators {
  /**
   * Проверяет наличие пробелов в начале и в конце строки
   *
   * @param control Контрол формы
   */
  export function unnecessarySpacesAlongEdgesOfString(control: AbstractControl): null | { unnecessarySpacesAlongEdgesOfString: { value: string } } {
    const controlValue: any = control.value

    if (controlValue === null || (typeof controlValue === "string" && controlValue.length <= 0)) {
      return null
    }

    const value: string = (controlValue as string)
    const isValid: boolean = value[ 0 ] !== " " && value[ value.length - 1 ] !== " "

    if (!isValid) {
      return {
        unnecessarySpacesAlongEdgesOfString: {
          value
        }
      }
    }

    return null
  }

  /**
   * Проверяет что число целое
   *
   * @param control Контрол формы
   */
  export function isInteger(control: AbstractControl): null | { isInteger: { value: number } } {
    if (isEmpty(control.value) || isEmptyString(control.value)) {
      return null
    }

    const val: number = parseFloat(control.value)

    if (typeof val === "number" && !isNaN(val) && val % 1 === 0) {
      return null
    }

    return { isInteger: { value: val } }
  }

  export function intervalMustBetween(days: number) {
    return (control: AbstractControl) => {
      const value: any = control.value
      if (isEmpty(value)) {
        return null
      }

      if (value.from instanceof Date && value.to instanceof Date) {
        const diff: number = differenceInDays(value.to, value.from)

        if (diff > days) {
          return {
            intervalMustBetween: {
              actual: diff,
              max: days
            }
          }
        }

        return null
      }

      throw new Error(`Unsupported value: ${ JSON.stringify(value) }`)
    }
  }


  /**
   * Проверяет значение, больше ли оно минимального
   *
   * @param minValue минимальное значение
   */
  export function excludeMin(minValue: number): ValidatorFn {
    return (control: AbstractControl): null | { excludeMin: { value: number } } => {
      const controlValue: number | null = control.value as number | null

      if (controlValue === null) {
        return null
      }

      const value: number = controlValue

      if (value <= minValue) {
        return {
          excludeMin: {
            value
          }
        }
      }
      return null
    }
  }
}
