import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ToLowerCasePipe } from "./to-lower-case.pipe"
import { ExecutePipe } from "./execute.pipe"
import { ToArrayPipe } from "./to-array.pipe"
import { ContrastColorPipe } from "./contrast-color.pipe"

@NgModule({
  declarations: [ ToLowerCasePipe, ExecutePipe, ToArrayPipe, ContrastColorPipe ],
  imports: [
    CommonModule
  ],
  exports: [
    ToLowerCasePipe,
    ExecutePipe,
    ToArrayPipe,
    ContrastColorPipe
  ]
})
export class PipeModule {
}
