import * as fromCore from "./core"
import * as fromUser from "./user"
import { RootState } from "../model"
import { orgReducer } from "./org"

export const reducers: { [key in keyof Partial<RootState>]: any } = {
  app: fromCore.reducer,
  user: fromUser.reducer,
  org: orgReducer
}
