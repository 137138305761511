import { Injectable, OnDestroy } from "@angular/core"
import { Subject } from "rxjs"

/**
 * Сервис абстракция для использования в takeUntil
 *
 * ```typescript
 * @Component({
 *  selector: 'button[wt-button]',
 *  templateUrl: './button.component.html',
 *  styleUrls: [ './button.component.scss' ],
 *  changeDetection: ChangeDetectionStrategy.OnPush,
 *  providers: [ DestroyService ]
 * })
 * export class ButtonComponent implements OnInit {
 *  constructor(private destroyService: DestroyService) {
 *  }
 * }
 * ```
 *
 * @see takeUntil
 */
@Injectable()
export class DestroyService extends Subject<void> implements OnDestroy {
  public ngOnDestroy(): void {
    this.next()
    this.complete()
  }
}
