import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core"
import { FaviconService } from "pmk/shared/shared-services"
import { environment } from "../../../../web-navigator/environments/environment"
import { AppearanceTheme } from "./appearance-theme"

function isSystemUseLightTheme(): boolean {
  return matchMedia("(prefers-color-scheme: light)").matches
}

@Component({
  selector: "pmk-theme-layout",
  templateUrl: "./theme-layout.component.html",
  styleUrls: [ "./theme-layout.component.scss" ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  host: {
    class: "pmk-theme-layout"
  }
})
export class ThemeLayoutComponent implements OnInit {
  private appearanceTheme: AppearanceTheme

  @Input()
  public set themeName(value: AppearanceTheme) {
    this.appearanceTheme = value
    if (value !== AppearanceTheme.system) {
      this.applyTheme(value)
      return
    }

    this.applySystemTheme()
  }

  constructor(private elementRef: ElementRef<HTMLElement>,
              private faviconService: FaviconService) {
  }

  public ngOnInit(): void {
    matchMedia("(prefers-color-scheme: light)").addEventListener("change", () => {
      this.setFavicon(isSystemUseLightTheme())
      this.applySystemTheme()
    })
  }

  private applyTheme(value: AppearanceTheme.dark | AppearanceTheme.light): void {
    const el: HTMLElement = this.elementRef.nativeElement
    el.dataset.theme = value
  }

  private applySystemTheme(): void {
    if (this.appearanceTheme !== AppearanceTheme.system) {
      return
    }

    const isSystemLightTheme: boolean = isSystemUseLightTheme()
    const appearanceTheme: AppearanceTheme.light | AppearanceTheme.dark = isSystemLightTheme ? AppearanceTheme.light : AppearanceTheme.dark
    this.applyTheme(appearanceTheme)
  }

  private setFavicon(isLightTheme: boolean): void {
    if (!environment.production) {
      return
    }

    if (isLightTheme) {
      this.faviconService.setHref("assets/image/waliot.png")
      return
    }

    this.faviconService.setHref("assets/image/waliot-light.png")
  }
}

