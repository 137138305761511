/**
 * Test whether a string is camel-case.
 */
const hasSpace: RegExp = /\s/
const hasSeparator: RegExp = /(_|-|\.|:)/
const hasCamel: RegExp = /([a-z][A-Z]|[A-Z][a-z])/

export function toNoCase(value: string): string {
  if (!value) {
    return ""
  }

  if (hasSpace.test(value)) {
    return value.toLowerCase()
  }

  if (hasSeparator.test(value)) {
    return (unseparate(value) || value).toLowerCase()
  }

  if (hasCamel.test(value)) {
    return uncamelize(value).toLowerCase()
  }

  return value.toLowerCase()
}

/**
 * Separator splitter.
 */

const separatorSplitter: RegExp = /[\W_]+(.|$)/g

function unseparate(value: string): string {
  return value.replace(separatorSplitter, (m, next) => {
    return next ? " " + next : ""
  })
}

const camelSplitter: RegExp = /(.)([A-Z]+)/g

function uncamelize(value: string): string {
  return value.replace(camelSplitter, (m, previous, uppers) => {
    return previous + " " + uppers.toLowerCase().split("").join(" ")
  })
}

export function toSpaceCase(value: string): string {
  return toNoCase(value).replace(/[\W_]+(.|$)/g, (matches: any, match: any) => {
    return match ? " " + match : ""
  }).trim()
}

export function toSnakeCase(value: string): string {
  return toSpaceCase(value).replace(/\s/g, "_")
}

export function toKebabCase(value: string): string {
  return toSpaceCase(value).replace(/\s/g, "-")
}

const preserveCamelCase: any = (input: string) => {
  let isLastCharLower: boolean = false
  let isLastCharUpper: boolean = false
  let isLastLastCharUpper: boolean = false

  for (let i: number = 0; i < input.length; i++) {
    const c: any = input[ i ]

    if (isLastCharLower && /[a-zA-Z]/.test(c) && c.toUpperCase() === c) {
      input = input.slice(0, i) + "-" + input.slice(i)
      isLastCharLower = false
      isLastLastCharUpper = isLastCharUpper
      isLastCharUpper = true
      i++
    } else if (isLastCharUpper && isLastLastCharUpper && /[a-zA-Z]/.test(c) && c.toLowerCase() === c) {
      input = input.slice(0, i - 1) + "-" + input.slice(i - 1)
      isLastLastCharUpper = isLastCharUpper
      isLastCharUpper = false
      isLastCharLower = true
    } else {
      isLastCharLower = c.toLowerCase() === c
      isLastLastCharUpper = isLastCharUpper
      isLastCharUpper = c.toUpperCase() === c
    }
  }

  return input
}

export const toCamelCase: any = (input: string, options?: any) => {
  options = Object.assign({
    pascalCase: false
  }, options)

  const postProcess: (x: any) => any = (x: any) => options.pascalCase ? x.charAt(0).toUpperCase() + x.slice(1) : x

  if (Array.isArray(input)) {
    input = input.map((x) => x.trim())
      .filter((x) => x.length)
      .join("-")
  } else {
    input = input.trim()
  }

  if (input.length === 0) {
    return ""
  }

  if (input.length === 1) {
    return options.pascalCase ? input.toUpperCase() : input.toLowerCase()
  }

  if (/^[a-z\d]+$/.test(input)) {
    return postProcess(input)
  }

  const hasUpperCase: boolean = input !== input.toLowerCase()

  if (hasUpperCase) {
    input = preserveCamelCase(input)
  }

  input = input
    .replace(/^[_.\- ]+/, "")
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (m: any, p1: any) => p1.toUpperCase())

  return postProcess(input)
}

export function replaceToPoint(from: string): number {
  return parseFloat(from.toString().replace(",", "."))
}


export class TextWidth {
  private canvas: HTMLCanvasElement = document.createElement("canvas")
  private context: CanvasRenderingContext2D = this.canvas.getContext("2d") as CanvasRenderingContext2D

  public setFont(font: string): void {
    this.context.font = font
  }

  public get(text: string): number {
    return this.context.measureText(text).width
  }
}
