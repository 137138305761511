import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"

export const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule)
  },
  {
    path: "app",
    loadChildren: () => import("./core/core.module").then((m) => m.CoreModule)
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "/login",
    pathMatch: "full"
  }
]

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }) ],
  exports: [ RouterModule ],
  providers: []
})
export class AppRoutingModule {
}
