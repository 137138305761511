import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store"
import { UserScope } from "@waliot/web-sdk"
import { LoadingState } from "pmk/shared"
import { RootState } from "../model"
import { UserState } from "../model/user"

/** @deprecated use {@link UserAtomService} */
export const selectFeatureUserState: MemoizedSelector<RootState, UserState> = createFeatureSelector<UserState>("user")
/** @deprecated use {@link UserAtomService} */
export const selectUserState: MemoizedSelector<RootState, UserState> = createSelector(selectFeatureUserState, (user: UserState) => user)
/** @deprecated use {@link UserAtomService} */
export const selectUid: MemoizedSelector<RootState, number | undefined> = createSelector(selectUserState, (user) => user.uid)
/** @deprecated use {@link UserAtomService} */
export const selectProfileLoadState: MemoizedSelector<RootState, LoadingState | undefined> = createSelector(selectUserState, (user) => user.loading)
/** @deprecated use {@link UserAtomService} */
export const selectOrgId: MemoizedSelector<RootState, number | undefined> = createSelector(selectUserState, (user) => user.orgId)
/** @deprecated use {@link UserAtomService} */
export const selectUserScopes: MemoizedSelector<RootState, UserScope[] | undefined> = createSelector(selectFeatureUserState, (user) => user.scopes)
