import { enableProdMode, NgModuleRef } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import "hammerjs"
import { FaviconService } from "pmk/shared/shared-services"

import { AppModule } from "./app/app.module"
import { environment } from "./environments/environment"

// eslint-disable-next-line no-console
console.log("VERSION NAME:", environment.versionCodeName)

if (environment.production) {
  enableProdMode()

  // http://www.network-science.de/ascii/
  // eslint-disable-next-line no-console
  console.log(`      ___           ___           ___                   ___           ___
     /\\__\\         /\\  \\         /\\__\\      ___        /\\  \\         /\\  \\
    /:/ _/_       /::\\  \\       /:/  /     /\\  \\      /::\\  \\        \\:\\  \\
   /:/ /\\__\\     /:/\\:\\  \\     /:/  /      \\:\\  \\    /:/\\:\\  \\        \\:\\  \\
  /:/ /:/ _/_   /::\\~\\:\\  \\   /:/  /       /::\\__\\  /:/  \\:\\  \\       /::\\  \\
 /:/_/:/ /\\__\\ /:/\\:\\ \\:\\__\\ /:/__/     __/:/\\/__/ /:/__/ \\:\\__\\     /:/\\:\\__\\
 \\:\\/:/ /:/  / \\/__\\:\\/:/  / \\:\\  \\    /\\/:/  /    \\:\\  \\ /:/  /    /:/  \\/__/
  \\::/_/:/  /       \\::/  /   \\:\\  \\   \\::/__/      \\:\\  /:/  /    /:/  /
   \\:\\/:/  /        /:/  /     \\:\\  \\   \\:\\__\\       \\:\\/:/  /     \\/__/
    \\::/  /        /:/  /       \\:\\__\\   \\/__/        \\::/  /
     \\/__/         \\/__/         \\/__/                 \\/__/
     `)
}

function bootstrap(): void {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { preserveWhitespaces: false })
    .then((moduleRef: NgModuleRef<AppModule>) => {
      const faviconService: FaviconService = moduleRef.injector.get(FaviconService)

      /**
       * Обновление фавикона на фавикон для разработки, чтобы
       * отличать вкладки прода и разработки
       */
      if (!environment.production) {
        faviconService.setHref("assets/image/waliot-dev.png")
      }
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err))
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (typeof window[ "cordova" ] !== "undefined") {
  document.addEventListener("deviceready", () => {
    console.log("native build") // eslint-disable-line no-console
    bootstrap()
  }, false)
} else {
  console.log("web build") // eslint-disable-line no-console
  bootstrap()
}
