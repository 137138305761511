import { Action, ActionReducer, createReducer, on } from "@ngrx/store"

import { changeCoreField, changeWindowMode } from "../actions"
import { CoreState, initialCoreState } from "../model"

const coreReducer: ActionReducer<CoreState, Action> = createReducer(
  initialCoreState,
  on(changeWindowMode, (state, { payload }) => ({ ...state, windowMode: payload })),
  on(changeCoreField, (state, { payload }) => ({ ...state, [ payload.prop ]: payload.value }))
)

export function reducer(state: CoreState, action: Action): CoreState {
  return coreReducer(state, action)
}
