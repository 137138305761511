/**
 * @deprecated use Record
 * @see {Record}
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class,@typescript-eslint/no-unused-vars
export class ArrayMap<T> {
  [ name: string ]: T
}

/**
 * @deprecated
 */
export interface ArrayMapConstructor {
  new <T>(): ArrayMap<T>
}

/**
 * @deprecated
 */
export function arrayToArrayMap<T>(arr: [ string, T ][]): ArrayMap<T> {
  const map: ArrayMap<T> = {}
  for (const [ key, value ] of arr) {
    map[ key ] = value
  }
  return map
}

/**
 * @deprecated
 */
export function toArrayMap<T>(arr: T[], fieldKey: string): ArrayMap<T> {
  const arrayMap: ArrayMap<T> = {}
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  arr.forEach((item) => arrayMap[ item[ fieldKey ] ] = item)
  return arrayMap
}

/**
 * @deprecated
 */
export function mapArrayMap<T, K>(arr: ArrayMap<T>, callback: (item: T, key: string, arr: ArrayMap<T>) => K): ArrayMap<K> {
  const map: ArrayMap<K> = {}
  for (const key in arr) {
    map[ key ] = callback(arr[ key ], key, arr)
  }
  return map
}

/**
 * @deprecated
 */
export function forEachArrayMap<T>(arr: ArrayMap<T>, callback: (item: T, key: string, arr: ArrayMap<T>) => void): void {
  for (const key in arr) {
    callback(arr[ key ], key, arr)
  }
}

/**
 * @deprecated
 */
export function arrayMapToArray<T>(arrMap: ArrayMap<T>): any[] {
  const arr: any[] = []
  for (const key in arrMap) {
    arr.push(arrMap[ key ])
  }
  return arr
}

