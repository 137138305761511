/* magic method converter base64 -> blob*/
export function b64toBlob(b64Data: string, contentType?: string, sliceSize?: number): Blob {
  const content: string = contentType || ""
  const sliceSiz: number = sliceSize || 512
  const byteCharacters: string = atob(b64Data)
  const byteArrays: any[] = []

  for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSiz) {
    const slice: string = byteCharacters.slice(offset, offset + sliceSiz)

    const byteNumbers: number[] = [ slice.length ]
    for (let i: number = 0; i < slice.length; i++) {
      byteNumbers[ i ] = slice.charCodeAt(i)
    }
    const byteArray: Uint8Array = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, { type: content })
}
