import { Serializer } from "./model"

export class EmptySerializer implements Serializer<any, any> {
  public deserialize(data: any): any {
    return data
  }

  public serialize(data: any): any {
    return data
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public serializable(data: any): boolean {
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public deserializable(data: any): boolean {
    return true
  }
}
