import { OperatorFunction } from "rxjs"
import { map } from "rxjs/operators"

export function getPayload(): OperatorFunction<any, any> {
  return map((action: any): any => action.payload)
}

export function payload<T>() {
  return (load: T) => {
    return {
      payload: load
    }
  }
}
